import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
//import { any } from '../_models/user.model';
//import { any } from '../_models/auth.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
const API_USERS_URLS = `${environment.serverUrl}`;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = "currentuser";

  // public fields
  currentUser$: Observable<any>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<any>;
  isLoadingSubject: BehaviorSubject<boolean>;


  get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: any) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<any>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  // public methods
  // login(email: string, password: string): Observable<any> {
  //   this.isLoadingSubject.next(true);
  //   let credentials = {
  //     username: email, password: password
  //   };
  //   return this.loginAPI(credentials).pipe(
  //     map((auth: any) => {
  //       //console.log(auth)
  //       const result = this.setAuthFromLocalStorage(auth);
  //       return result;
  //     }),
  //     switchMap(() => this.getUserByToken()),
  //     catchError((err) => {
  //       console.error('err', err);
  //       return of(false);
  //     }),
  //     finalize(() => this.isLoadingSubject.next(false))
  //   );
  // }

  loginAPI(credentials: any): Observable<any> {
    return this.http.post<any>(`${API_USERS_URLS}/Login`, credentials);
  }
  sendotp(credentials: any): Observable<any> {
    return this.http.post<any>(`${API_USERS_URLS}/sendotp`, credentials);
  }
  confirmOTP(credentials: any): Observable<any> {
    return this.http.post<any>(`${API_USERS_URLS}/verifyotp`, credentials);
  }
  updatePass(credentials: any): Observable<any> {
    return this.http.post<any>(`${API_USERS_URLS}/resetpassword`, credentials);
  }
  logout() {
    localStorage.clear();
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }
    
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}

